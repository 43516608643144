/**Start Index page load */
.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.loader:after {
  content: ' ';
  display: block;
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**End Index page load */
/**Start accessibility */
.h-sr-only {
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  margin: -1px;
  overflow: hidden;
}

.h-sr-only-focusable:focus,
.h-sr-only-focusable:active {
  position: static;
  width: auto;
  height: auto;
  clip: auto;
  margin: 12px 16px !important;
  overflow: visible;
}

.hide-content {
  display: none;
  visibility: hidden;
}

.show-content {
  display: flex;
  visibility: visible;
}

h1:focus,
a:focus,
button:focus {
  /* color: inherit !important; */
  /*outline-color: #000 !important;*/
  outline-style: dashed !important;
  outline-width: 1px !important;
  outline-offset: 2px !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

a:active,
a:hover {
  color: inherit !important;
  border-bottom: none !important;
  outline: 0px;
}

button:hover {
  cursor: pointer;
}

button:active,
a:hover {
  outline: 0px;
}

.C-Button.\--raised:focus .C-Button__innerContent {
  color: #ffffff !important;
}

.vm-header-menu .C-DropdownMenuItem__item:focus {
  color: #333333 !important;
}

/**End accessibility */
#footer {
  color: #747474;
  font-weight: normal;
  line-height: 16px;
  text-decoration: none;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}

/**Start Global */
.vmm_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vmm_align_center_middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vmm_flex_center {
  display: flex;
  align-items: center;
}

.vm_container {
  min-height: 84vh;
  box-shadow: none !important;
}

.vm_blue_bg {
  background-color: #366cd9;
}

.mla {
  margin-left: auto;
}

/**End Global */
.vm_table th,
.vm_table td {
  margin-left: 5px;
  padding-right: 5px;
}

.vm_table a:hover {
  text-decoration: underline !important;
}

.vm_table th button.ascending::after {
  content: '\2193';
  color: inherit;
  display: inline-block;
  margin-left: 5px;
}

.vm_table th button.descending::after {
  content: '\2191';
  color: inherit;
  display: inline-block;
  margin-left: 5px;
  z-index: 1;
}

.vm_table thead th button,
vm_table thead th button:focus {
  background: none !important;
  font-weight: 700;
  color: inherit !important;
  font-size: inherit;
  width: 100%;
  position: inherit;
  padding: 0;
  justify-content: left;
  text-transform: inherit;
}

/**Start Table */
/**End Table */
.vm_header_search {
  width: 30vw;
  border-radius: 25px;
}

.vm_header_user {
  display: inline-flex;
  align-items: center;
}

.training-header {
  position: relative;
  left: -100px;
}

/*.vm_header_user p {*/
/*  margin-top: 4px;*/
/*}*/
.vm_display_block {
  display: block;
}

.vm_page_head {
  margin-right: auto;
}

.home-card .card {
  min-height: 200px;
}

.location_table_cell {
  display: flex;
  min-width: 0;
}

.location_table_address * {
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.font-force-white {
  color: white !important;
}

.margin-left-toggle-external {
  margin-top: 6%;
  margin-left: 50%;
}
.margin-left-toggle-internal {
  margin-top: 6%;
  margin-left: 130%;
}
.vm_pos_rel {
  position: relative;
}

@media (max-width: 750px) {
  .hide_for_mobile {
    display: none;
  }

  .vm_header_search {
    width: 40vw;
  }

  .vm-mobile .vm-no-margin {
    margin: 0 !important;
  }

  .vm-mobile .vm-no-padding {
    padding: 0 !important;
  }

  .vm-mobile .vm-normal-padding {
    padding: 8px !important;
  }

  .vm-mobile .C-Button {
    font-size: 0.8em !important;
  }

  .vm-mobile.--xs-padding-normal {
    padding: 8px !important;
  }

  .vm-mobile h1 {
    font-size: 1.1em !important;
  }

  .vm-mobile h2 {
    font-size: 1em !important;
  }

  .vm-mobile h3 {
    font-size: 1.4em !important;
  }

  .vm-mobile h3 {
    font-size: 1.4em !important;
  }

  .vm-mobile .C-Button__innerContent {
    padding-left: 8px;
    padding-right: 8px;
  }

  .vm-mobile .C-GridItem {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .vm-confirm-modal .C-Modal__container {
    width: 80vw !important;
  }
}

.vm-list-inline ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.vm-list-inline li {
  display: inline-block;
  width: 25%;
}

.vm-list-inline li .C-ListItem__item {
  padding-left: 0px;
  border-left: 0px;
}

.vm-list-inline-edit li {
  width: 100%;
}

.vm-list-inline-edit-year li {
  width: 32%;
}

.vm-list-inline-edit-year li .C-ListItem__item {
  padding-left: 0px;
  border-left: 0px;
}


.vm-list-inline-edit li .C-ListItem__item {
  padding-left: 0px;
  border-left: 0px;
}

.vm-list-inline-request-edit li {
  display: inline-block;
  width: 33%;
}

.vm-list-inline-request-edit li .C-ListItem__item {
  padding-left: 0px;
  border-left: 0px;
}

.vm-info-edit-row {
  display: flex;
  justify-content: flex-start;
}

.vm-duns-form {
  width: 65%;
}

.vm-edit-basic-info-form {
  width: 100% !important;
}

.vm-supplier-name-edit-form {
  width: 93%;
}

.vm-edit-supplier-name-button {
  top: 1.7rem;
  left: -1.8rem
}

.vm-basic-info-cancel-edit {
  top: -2rem !important;
}

@media screen and (max-width: 500px) {
  .vm-list-inline li {
    width: 100%;
  }

  .vm_home_tab .hc-ml-expanded {
    margin-left: 0px !important;
  }
}

.vm-register-page .C-Layout__headerBackground {
  height: 0px;
  padding: 0px;
}

.C-Layout.vm-register-page {
  padding-top: 0 !important;
}

.C-Layout__header,
.C-Layout__headerBackground {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  height: 4.5em;
  padding: 6px;
  max-width: 100%;
  color: #cccccc;
  z-index: 7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
}

.C-Heading {
  color: inherit;
}

.C-Layout {
  /* padding-top: 4.5em; */
}

.C-SideNav {
  margin-top: 4.5em;
}

.C-Layout__body {
  min-height: 80vh;
  padding: 0;
}

.C-Layout__menuButton {
  padding: 0;
}

.C-SideNav__NavigationItem.isSelected {
  border-left-color: inherit;
  /* color: inherit; */
}

.C-Avatar.heading {
  border: 1px solid #d8d8d8;
}

.C-SideNav__Navigation {
  margin: 2px;
}

.C-SideNav__SkipLink {
  background-color: inherit;
}

.C-SideNav__SkipLink:focus {
  text-decoration: underline;
}

.C-FullScreen.isVisible {
  z-index: 7;
}

.C-ButtonGroup {
  display: inline-flex;
}

.vm_ml5 {
  margin-left: -5em;
}

@media (min-width: 1600px) {
  .C-Layout__body.\--include-rail {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1 / span 5 !important;
  }
}

@media (min-width: 1200px) {
  .C-Layout__body {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1 / span 5 !important;
  }

  .C-Layout__body.\--include-rail.vm-with-side-nav {
    grid-column: 2 / span 5 !important;
  }
}
.vm-small-side-nav-margin {
  margin-left: 3%;
}

@media (max-width: 1366px) {
  .vm-supplier-tabs-card {
    min-width: 1366px;
    overflow-x: auto;
  }
}

.vm-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vm-button-selection-icon .C-Dropdown .C-Button__innerContent {
  padding-left: 2px;
  padding-right: 2px;
}

.vm-vertical-line {
  border-left: 1px solid #9d9e9f;
  height: 100%;
}

.vm-confirm-modal .C-Modal__container {
  width: 30vw;
}

.vm-supplier-subtype-checkbox {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 'auto';
}

.vm-supplier-checkbox {
  display: inline-block;
  width: 96%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.vm-supplier-list {
  padding: 0;
}

.vm-spinner-white {
  border-right-color: #ffffff !important;
  border-color: #e6e4e4;
}

.vm-target-setup {
  border: 1px solid #d6d6d6;
  border-bottom: none;
}

.vm-target-setup td,
.vm-target-setup th {
  margin-left: 1vw;
}

.vm-text-bold {
  font-weight: bolder !important;
}

.vm_text_bold {
  font-weight: 700;
}

.vm-column-two li {
  width: 50%;
}

.vm-alert-color {
  color: #eb3c28 !important;
}

.vm-alert-icon-doc {
  margin-right: 5px;
}

.vm-alert-bg-color {
  background: #eb3c28;
}

.vm-alert-box {
  background-color: #fff4db;
}

.vm-alert-box-icon {
  font-size: 2em !important;
  width: auto !important;
}

.vm-alert-icon {
  font-size: 1.5em !important;
  bottom: 0px !important;
  left: 1em;
  position: relative;
}

.vm-alert-icon + p {
  margin-top: 3px;
}

.vm-alert-tab {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 5px;
  right: 1.1rem;
  display: block;
  border-radius: 50%;
}
.C-Tabs__Button {
  position: relative;
}

.vm-alert-tab-payment {
  width: 8px;
  height: 8px;
  position: relative;
  top: -0.3rem;
  left: -1rem;
  display: block;
  border-radius: 50%;
}

.vm-alert-badge:before {
  background: #eb3c28;
  min-width: 40px;
  margin-left: -1.4em;
  margin-top: -0.7em;
}
.vm-alert-badge-disableLink {
  cursor: not-allowed;
  text-decoration: none;
}

.vm-alert-badge-disable::before {
  background-color: var(--canvas-background-color--default);
  cursor: not-allowed;
  pointer-events: none;
}

.overview-banner.vm-alert-button {
  color: white;
  background-color: red;
  min-width: 40px;
  border-radius: 20px;
  height: 25px;
  border-color: transparent;
}

.vm-alert-blue:before {
  border: 1px solid #3e72db;
  color: #3e72db;
  background: #ffffff;
  min-width: 40px;
}

.vm-alert-badge-null:before {
  background: #b3b3b3;
  min-width: 40px;
  width: 40px;
}

table.vm-target-no-border {
  border: none;
}

table.vm-target-no-border td,
table.vm-target-no-border th {
  margin-left: 0px;
}

.vmm_pos_rel {
  position: relative;
}

.vmm__add_pol__spinner {
  position: absolute;
  top: 40px;
  right: -15px;
}

.vmm__fullscreen__button {
  position: absolute;
  top: 0;
  right: 25px;
}

b {
  /* for email*/
  font-weight: 700;
}

@media (max-width: 1200px) {
  .C-Overlay.\--background-dark {
    /*fix when width is less than 500 */
    background: none;
  }
}

.C-Button.\--icon {
  /*fix global nav */
  background: none;
  box-shadow: 0 0 black;
  border: 0;
  color: inherit;
}

.C-Button {
  /*min-height: 36px;*/
}

.C-Button.isDisabled,
.C-Button.isDisabled:hover {
  border: 1px solid;
}

.C-Chip .C-Button.isDisabled {
  border: 0;
}

.C-FormField--Checkbox.isDisabled {
  color: #888;
}

.C-FormField--Checkbox__input:disabled + .C-FormField--Checkbox__box {
  border: 1px solid;
}

/***/
.C-FullScreenButton.C-Button.\--icon {
  background-image: url('../images/close.svg');
  background-repeat: no-repeat;
  background-size: 75% 50%;
  background-position-x: 10px;
  background-position: 4px;
  z-index: 1;
}

.C-FullScreenButton.C-Button.\--icon [data-icon] {
  position: absolute;
  top: -50px;
  z-index: -2;
}

.C-FullScreenButton.C-Button.\--icon:focus {
  outline-color: #ffffff !important;
}

.vm-supplier-type-msg {
  border: 1px solid #000000;
  border-radius: 2px;
}

.C-Tooltip:focus {
  outline-style: dashed !important;
  outline-width: 1px !important;
  outline-offset: 2px !important;
}

.sm_header .C-Divider__line {
  background-color: #4d4d4d !important;
}

.sm_flex {
  display: flex;
}

@media screen and (max-width: 500px) {
  /* Mobile styles */
}

@media screen and (min-width: 501px) and (max-width: 1200px) {
  /* Tablet styles */
  .hide_for_tablet {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  /* Desktop styles */
}

#skipLinks {
  position: absolute;
  z-index: 20;
  width: auto;
  height: auto;
  clip: auto;
  background-color: #0366d6 !important;
  color: #ffffff !important;
}

.sm_button.C-Button .C-Button__innerContent:focus {
  color: inherit !important;
  outline-color: #000 !important;
  outline-style: dashed !important;
  outline-width: 1px !important;
  outline-offset: 2px !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

.sm__skeleton {
  height: 32px;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
}

.sm_text_ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sm__ship_details__table-ext {
  /* This display is not needed here since it is already bieng added from canvas, but just using it here as failsafe */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: initial;
}

.sm__ship_details__table-ext > * {
  flex: 1 0 13%;
  word-break: keep-all;
  /* https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
}

.sm__ship_details__table-ext-sec {
  /* This display is not needed here since it is already bieng added from canvas, but just using it here as failsafe */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: initial;
}

.sm__ship_details__table-ext-sec > * {
  flex: 1 0 13%;
  /* https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
}

.sm__gtsp__table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: initial;
}

.sm__gtsp__table > * {
  flex: 1 0 13%;
  min-width: 0;
}

.sm__ship_details__table_content {
  overflow-wrap: 'break-word';
  max-width: '90px';
}

.sm_reset_zindex {
  z-index: 1;
}

.sm_add_dropdown {
  z-index: 1;
}

@media (max-width: 1200px) {
  .sm_add_dropdown {
    z-index: unset;
  }
}

.sm_document_card {
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sm_coi_card {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sm_scc_form_status {
  display: flex;
  justify-content: space-between;
}

.vm-target-setup__table-ext {
  /* This display is not needed here since it is already bieng added from canvas, but just using it here as failsafe */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.vm-target-setup__table-button {
  flex: 0 1 13%;
}

.vm-target-setup-border {
  border: 1px solid #d6d6d6;
  border-bottom: none;
}

/* Default width is 50vw, but in this case Modal content much more. So overriding it. */
.sm__modal__width .C-Modal__container {
  max-width: 90vw !important;
}

.sm__relationship__modal__width .C-Modal__container__content.--scroll-contained  {
  max-height: 45vw;
  overflow-x:hidden;
  overflow-y: auto;
}

.sm__audit__table-sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  word-break: initial;
}

.sm__audit__table__filter-dropdown {
  z-index: 6;
}

.sm__audit__table-body {
  display: block;
  max-height: 35vh;
  overflow-x: hidden;
}

.todo_badge_value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1px;
  color: #fff;
  z-index: 11;
}

.bannerProgressWrapper {
  display: flex;
  align-items: center;
}

.bannerProgress {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.bannerProgressText {
  margin: 0 10px;
  text-align: left;
}

/* Reset class*/
.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e72db !important;
}

/* Utility classes */
.padding0 {
  padding: 0;
}

.margin0 {
  margin: 0;
}

.fontSize16 {
  font-size: 16px;
}

.section-wrapper {
  margin-top: 4rem;
  height: 93vh;
  background: #24272c;
}

.default-background {
  background-color: #f7f7f7 !important;
}

.fullscreen-header {
  /* background-color: var(--canvas-background-color--default-contrast-weak) !important;
   */
  background-color: var(--canvas-theme-color--background) !important;
  color: #fff;
}
.vm__location_list__skeleton-fullscreen {
  position: absolute;
  top: 0;
  right: 25px;
}

.vm-location-contact {
  border: 1px solid #d6d6d6;
  border-bottom: none;
}

.vm-location-contact td,
.vm-location-contact th {
  margin-left: 1vw;
}

.location-contact-data {
  margin-top: 7px;
}

.C-Layout.C-Theme--default {
  --canvas-z-index--overlayContent: 8;
}
/* .C-Input--type-info.isErrored {
  height: 40px;
} */

.shipping-details-filter + .enterprise-icon {
  right: 20px;
}

.todos-dropdown {
  border-radius: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.todos-dropdown::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.todos-dropdown::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.todos-dropdown-position {
  width: 340px !important;
  margin-left: -230px !important;
  max-height: 360px !important;
  overflow-y: auto !important;
}

.todos-dropdown-position-candidate {
  width: 345px !important;
  margin-left: -240px !important;
  max-height: 360px !important;
  overflow-y: auto !important;
}

.todos-dropdown-position-started {
  width: 345px !important;
  margin-left: -220px !important;
  max-height: 360px !important;
  overflow-y: auto !important;
}

.todos-dropdown-position-under-review {
  width: 345px !important;
  margin-left: -264px !important;
  max-height: 360px !important;
  /* overflow-y: auto !important; */
  margin-top: 10px;
  overflow: unset !important;
}

.header-under-review {
  max-width: 343px !important;
  margin-left: -4px !important;
  max-width: '100%';
  margin-bottom: -10px !important;
}

.todos-dropdown-position-approved {
  width: 345px !important;
  margin-left: -235px !important;
  max-height: 360px !important;
  overflow-y: auto !important;
}

.todos-progress-bar {
  border: 1px solid;
  border-color: var(--canvas-background-color--interactive);
  height: 4px;
}

.todos-progress-bar-completed {
  border: 1px solid;
  border-color: var(--canvas-background-color--success);
  height: 4px;
}

.todo-header-container {
  position: sticky;
  top: 0;
  background-color: var(--canvas-background-color--dropdown);
  z-index: 8;
}

.todo-header-item {
  margin-top: -3.4%;
}

.todo-header {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: var(--canvas-font-color--default);
}

.todo-avatar {
  margin-top: 2px;
}

.todo-display-text {
  font-family: Helvetica;
  font-weight: 500;
  font-size: 14px;
}

.todo-supplier-status-completed {
  font-family: Helvetica;
  font-weight: 500;
  font-size: 14px;
  color: var(--canvas-font-color--success);
}

.todo-supplier-status-not-started {
  font-family: Helvetica;
  font-weight: 500;
  font-size: 14px;
  color: var(--canvas-font-color--default);
}

.todo-supplier-status-in-progress {
  font-family: Helvetica;
  font-weight: 500;
  font-size: 14px;
  color: var(--canvas-font-color--interactive);
}

.disabled-todo-text {
  color: var(--canvas-font-color--disabled);
}

.completed-todo-text {
  color: var(--canvas-font-color--default);
}

.disabled-todos-avatar {
  color: var(--canvas-font-color--disabled);
}

.todo-date,
.view-todos-text,
.todo-description {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.todos-review-badge::before {
  padding-left: 6px;
  padding-right: 6px;
}

.todo-container {
  line-height: 21px;
  max-width: 345px;
  margin-left: -5px !important;
}

.todo-container:hover div {
  background-color: var(--canvas-helper-color--grey07);
}

.todo-supplier-type-header {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 13px;
}

.todo-completed-icon {
  color: var(--canvas-icon-color--success);
}

.todo-pending-icon {
  color: var(--canvas-background-color--alert);
  background-color: black;
  border-radius: 10px/10px;
}

.sm-full-screen-zindex {
  z-index: 9 !important;
}

.sm-modal-zIndex {
  z-index: 10 !important;
}

.sm-dropdown-zIndex {
  z-index: 12 !important;
}

.home-screen-header {
  width: 100%;
  background-color: #E8EEFC;
  border-bottom: 1px solid var(--canvas-background-color--interactive);
  border-top: none;
}

.home-screen-header-dark {
  width:100%;
  /* background-color:black; */
  background-color: var(--canvas-background-color--card);
  border-color: var(--canvas-background-color--interactive);
}

.home-screen-header-button {
  margin-left: 25%;
}

.home-screen-header-text {
  color: #2951A3;
}

.duns-message-text {
  color: #2951A3 !important;
}

.home-screen-header-icon {
  position: absolute;
  color: #3D70D6;
}

.basic-info-save-button {
  top: 10%;
}

.home-screen-alert-color {
  color: #936406 ;
  margin-left: 10px;
}

.home-screen-alert-text {
  color: #936406 ;
  margin-left: 2px;
}

.home-section-header-text {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 20px;
}

.vm-duns-update-notifcation {
  width: 100%;
  background-color: #FEF9C3;
  border-bottom: 1px solid #C38A04;
  border-top: none;
}

.vm-duns-update-text {
  font-family: Helvetica;
  color: #583A0E;
}

.vm-duns-update-alert-icon {
  color: #C38A04
}

.edit-duns-modal {
  width: 80%;
}

.todos-card {
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.basic-info-card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.duns-notification {
  border-bottom: 1px solid var(--border-color-interactive, #3D70D6);
  background: var(--background-color-interactive-contrast-weak, #E8EEFC);
  color: #2951A3;
}

.duns-text {
font-family: Helvetica for Target;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
color: #676B74;
}

.duns-text-dark {
  font-family: Helvetica for Target;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #babcc0;
  }

.duns-caption {
color: #2B2D31;
font-family: Helvetica for Target;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
}

.duns-caption-dark {
  color:white;
  font-family: Helvetica for Target;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  }

.duns-divider {
  z-index: -1;
  }

/* brands */
.brand_question {
  display: flex;
}

.brand_question > * {
  margin-right: 30px;
}
.brand_filter_placeholder input::placeholder{
  font-size: 0.77rem;
}
.C-InputRadio__Option {
  padding-right: 5px;
}
/* .C-DatePicker__MonthIcons {
  top: 3px;
} */

.relationship-icon {
  margin-top: 4%;
  text-align: center;
  height: 60px !important;
}

.relationship-back-button-icon {
  margin-top: 1%;
  text-align: left !important;
  height: 30px !important;
}
.no-relationship-text {
  margin-top: 1%
}
.no-relationship-info-text {
  text-align : center;
  margin-left: 37%;
  width: 28%;
  margin-top:2%;
  font-weight: bolder ;
}

.no-relationship-info-list{
  margin-top:2%;
  text-align : center;
}

.no-relationship-info-list-item{
  text-align : center;
}

.vm-list-beginner {
  width: 8px;
  height: 8px;
  top: 5px;
  border-radius: 50%; 
  background-color: #000;
}

.vm-center-div { 
  margin-left: 23%;
  margin-bottom: 2%;
}

.vm-center-div-left { 
  margin-left: 23%;
}

.vm-relationship-info-list {
display: flex;
justify-content: center;
}

.vm-relationship-header-text {
padding-left: 21%; 
padding-top: 2%;
width: 81%;
}


.vm-relationship-card {
  width:68%;
}

.vm-relationship-contact-helper {
color: var(--canvas-font-color--disabled);
padding-left: 2%;
font-size: 24px;
}

.vm-relationship-render-pos {
  padding-left: 2%;
}

.vm-relationship-title-text {
 padding-left: 2%;
 padding-top: 2%;
 padding-bottom: 2%;
}


.vm-relationship-title-contacts-text {
  padding-left: 2%;
  padding-top: 2%;
  width: 90%;
  text-align: left !important;
 }

.vm-relationship-back-button {
padding-bottom: 16px;
padding-top: 2%
}

.vm-relationship-share-contact {
 width: 20% !important;
 padding-left: 4%;
}

.vm-relationship-review-contact-helper {
  width: 30% !important;
  padding-left: 4%;
  height: 24px;
  padding-top: 1% !important; 
 }

.vm-relationship-helper-section {
  background-color: #FFF9F9;
  padding-left: 0px;
}


.vm-relationship-helper-section-dark {
  background-color: #282727;
  padding-left: 0px;
}

.vm-relationship-helper-header {
font-size: 1vw ;
}

.vm-relationship-helper-definition {
  padding-left: 2%;
  height: 100%;
}

.vm-relationship-helper-logo-pos {
  padding-left: 90%;
  padding-top: 29;
}

.vm-relationship-starter-section {
  padding-left: 12px;
  padding-top: 12px;
}

.vm-relationship-starter-sub-section {
  padding-left: 20%
}

.vm-relationship-starter-cancel {
  padding-bottom: 16px;
}

.vm-relationship-request-text {
 font-size: 32px;
}
.vm-relationship-progress-bar-title {
 width: 48%;
}
.vm-relationship-progress-bar {
  width: 69%;
}
.vm-relationship-email-request-text {
  padding-left: 1.8%;
  padding-top: 2%;
  width: 90%;
}
.vm-relationship-email-request-text-compact {
  padding-left: 1.8%;
  padding-top: 2%;
  width: 100% !important;
}
.vm-relationship-email-request-desc {
  padding-left: 2%;
  text-align: left;
}

.vm-relationship-typedef-text {
  padding-left: 2%;
  text-align: left;
  padding-top: 2%;
}

.vm-width-20 {
  width: 20%;
}

.vm-width-25 {
  width: 25%;
}

.vm-width-60 {
  width: 60%;
}

.vm-relationship-email-request-header {
  font-size: 20px;
  padding-left: 2%;
 }

 .vm-relationship-review-request-header {
  font-size: 20px;
  padding-left: 1.6%;
 }

 .vm-align-center {
 min-height: '60vh';
 justify-content: 'center' 
 }

 .vm-height-75 {
  min-height: '75vh';
 }

.vm-center-height-60 {
  min-height: '60vh';
  width: '400vh';
  justify-content: 'center';
}

.vm-width-68 {
  width: 68%;
 }

 .vm-width-25 {
  width: 25%;
 }

 .vm-width-30 {
  width: 30%;
 }

 .vm-padding-left-20 {
  padding-left:'20px';
 }

 .vm-padding-left-5 {
  padding-left:'5px';
 }

 .vm-padding-top-20 {
  padding-left:'20px';
 }
 
 .vm-relationship-review-header-text {
  font-size:1.5vw;
  padding-top:'14px' ;
}

